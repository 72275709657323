$desktop-slider-height: 480px;
$mobile-slider-height: 300px;

.top-slider {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  position: relative;
  background-color: #ffffff;

  @include media-breakpoint-down(md) {
  }

  @include media-breakpoint-up(lg) {
  }


  .owl-theme {
    &.owl-carousel {
      height: $desktop-slider-height;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        height: $mobile-slider-height;
      }

      .owl-item {
        img {
          width: 100%;
          height: $desktop-slider-height;
          object-fit: cover;

          @include media-breakpoint-down(md) {
            height: $mobile-slider-height;
          }
        }
      }
    }

    .custom-nav {
      position: absolute;
      top: 38%;
      left: 0;
      right: 0;

      @include media-breakpoint-down(md) {
        top: 28%;
      }

      .owl-prev, .owl-next {
        position: absolute;
        height: 100px;
        color: inherit;
        background: none;
        border: none;
        z-index: 100;
        outline: none;

        &:hover {
          background-color: rgba(255, 255, 255, 0.65);

          i {
            color: #000000;
          }
        }

        i {
          font-size: 2.5rem;
          color: #cecece;
        }
      }

      .owl-prev {
        left: 0;
      }

      .owl-next {
        right: 0;
      }
    }
  }

}