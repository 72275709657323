.take-tour-footer {
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;

  position:fixed;
  bottom:0;
  width:100%;
  z-index: 1000;  // this make sure the panel are on the top

  &-content {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-up(lg) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .take-tour-item-list {
    width: 100%;
    border-radius: 4px;
    display: flex;

    @include media-breakpoint-up(lg) {
      padding-left: 40px;
      padding-right: 40px;
      justify-content: space-between;
    }

    @include media-breakpoint-down(md) {
      padding-left: 16px;
      padding-right: 16px;
      justify-content: center;

      .take-tour-item {
        width: 100%;

        .take-tour-item-btn-block {
          display: flex;
          width: 100%;

          button {
            display: flex;
            flex: 50%;
            justify-content: center;
    
            &:first-of-type {
              margin-right: 8px;
            }
    
            &:last-of-type {
              margin-left: 8px;
            }
          }
        }
      }
    }

    .take-tour-item {
      height: 76px;
      display: flex;
      align-items: center;
    }

    .take-tour-item-btn-block {    
      .btn-contact {
        width: 142px;
        padding-top: 11px;
        padding-bottom: 11px;
        border-radius: 2px;
        color: #222222;
        border: solid 1px rgba(34, 34, 34, 0.5);

        @include media-breakpoint-up(lg) {
          margin-right: 16px;
        }
      }

      .btn-tour {
        width: 142px;
        padding-top: 11px;
        padding-bottom: 11px;
        border-radius: 2px;
        color: #ffffff;
        background-color: var(--btn-primary-bg-color);
      }

    }
  }

}

.section-learn-more {
  background-color: var(--learn-more-bg-color);

  @include media-breakpoint-down(md) {
    .container {
      display: flex;
      align-items: center;
      justify-content: center;

      flex-direction: column;

      // mobile
      .cta-message {
        color: white;
        margin-top: 24px;
        margin-bottom: 16px;
        h2 {
          font-size: 1.6rem;
        }
      }

      .cta-button {
        color: white;
        border-radius: 1px;
        border: solid 1px rgba(255, 255, 255, 0.5);
        padding-top: 11px;
        padding-bottom: 11px;
        margin-bottom: 24px;
        width: 100%;

        a {
          font-weight: bold;
          font-size: 16px;
        }
      }

    }
  }

  @include media-breakpoint-up(lg) {
    .container {
      display: flex;
      align-items: center;
      justify-content: center;

      flex-direction: row;

      padding-top: 42px;
      padding-bottom: 42px;

      .cta-message {
        color: white;
        margin: 0;
        padding-right: 40px;
        font-size: 2rem;
      }

      .cta-button {
        color: white;
        border-radius: 1px;
        border: solid 1px rgba(255, 255, 255, 0.5);
        margin: 0;
        padding: 11px 24px;
      }
    }
  }

}

footer {
  background-color: #222222;
  color: white;

  .footer-nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-bottom: 8px;
    box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.25);

    .footer-logo {
      margin: 32px 0;
      width: auto;
      height: 48px;

      &__sur {
        height: 38px;
      }
    }

    .footer-menu {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        padding-left: 60px;
        justify-content: space-between;
      }

      padding: 0;
      margin: 0;
      list-style: none;

      li {      
        display: flex;
        justify-content: center;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }

        a {
          color: white;
          text-transform: uppercase;
          font-size: 14px;
        }
      }
    }
  }

  .copyright-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    color: #ffffff;
    opacity: 0.5;
    padding: 24px 15px;

    .broker-reciprocity-notice {
      padding-bottom: 15px;
      text-align: justify;
    }
  }

  @include media-breakpoint-up(lg) {
    .footer-nav-container {
      flex-direction: row;
      justify-content: space-between;

      img {
        margin: 48px 0;
      }
    }
  }

}

