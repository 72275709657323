.listing-detail-page {
  // https://github.com/w3c/csswg-drafts/issues/129#issuecomment-521936665
  .section-footer {
    margin-bottom: 76px; //take-tour-footer height
  }

  .home-type-section {
    .container {
      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }
    }
  }
}
