// Pagination: DEFAULT
.pagination {
    margin: 0;
    border-radius: 0;
}

//.pagination .page-item {
//    margin-right: 16px;
//}

.pagination .page-link,
.pagination .page-item > span {
    //padding: 0.625rem 0.875rem;
    //font-family: $font-family-alt;
    //font-size: $pagination-font-size;
    color: $pagination-color;
    background-color: $pagination-bg;
    border: 1px solid $pagination-border-color;
    text-align: center!important;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:first-child > span {
    margin-left: 0;
    border-top-left-radius: $pagination-border-radius;
    border-bottom-left-radius: $pagination-border-radius;
}

.pagination .page-item:last-child .page-link,
.pagination .page-item:last-child > span {
    border-top-right-radius: $pagination-border-radius;
    border-bottom-right-radius: $pagination-border-radius;
}

.pagination .page-item .page-link:focus,
.pagination .page-item .page-link:hover,
.pagination .page-item > span:focus,
.pagination .page-item > span:hover {
    color: $pagination-color-hover;
    background-color: $pagination-bg-hover;
    border-color: $pagination-border-color-hover;
}

.pagination > .active .page-link,
.pagination > .active .page-link:focus,
.pagination > .active .page-link:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    color: $pagination-color-active;
    background-color: $pagination-bg-active;
    border-color: $pagination-border-color-active;
}

