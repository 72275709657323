$ribbon-width: 32px;
$ribbon-tip-width: $ribbon-width / 2;
$ribbon-tip-bottom-adjustment: $ribbon-width / 4;
$star-size: 22px;

.featured-listing-ribbon {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: $ribbon-width;
  height: 30px;
  background-color: $featured-listing-ribbon-bg;
  margin-left: 16px;

	&:after {
		content: '';
		position: absolute;
		z-index: 2;
		left: 0;
		bottom: -#{$ribbon-tip-bottom-adjustment};
		border-left: $ribbon-tip-width solid $featured-listing-ribbon-bg;
		border-right: $ribbon-tip-width solid $featured-listing-ribbon-bg;
		border-bottom: $ribbon-tip-bottom-adjustment solid transparent;
  }
  
  .fa-star {
    color: $white;
    font-size: $star-size;
  }
}