.home-page {
  .hero {
    @include media-breakpoint-up(lg) {
      padding-bottom: 150px;
    }
  }

  .home-section {
    position: relative;

    @include media-breakpoint-up(lg) {
      margin-bottom: 80px;
      margin-top: -72px;
    }
  
    .container {
  
      @include media-breakpoint-up(lg) {
        width: 940px;
        padding-left: 0;
        padding-right: 0;
        border-radius: 4px;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
      }
  
      @include media-breakpoint-down(md) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
  
      .attribute-section {
        background-color:#f6f6f6;
  
        @include media-breakpoint-down(md) {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 16px;
          padding-top: 16px;
        }
  
        @include media-breakpoint-up(lg) {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 32px 0;
        }
  
        &-item {
          @include media-breakpoint-down(md) {
            flex: 0 50%;
            display: flex;
            justify-content: center;
            align-items: center;
  
            &:nth-child(1) {
              box-shadow: inset -1px -1px 0 0 rgba(34, 34, 34, 0.25);
            }
            &:nth-child(2) {
              box-shadow: inset 0 -1px 0 0 rgba(34, 34, 34, 0.25);
            }
            &:nth-child(3) {
              box-shadow: inset -1px 0 0 0 rgba(34, 34, 34, 0.25);
            }
            &:nth-child(4) {
              box-shadow: inset 0 0 0 0 rgba(34, 34, 34, 0.25);
            }
          }
  
          @include media-breakpoint-up(lg) {
            width: 235px;
            display: flex;
            justify-content: center;
            align-items: center;
  
            &:not(:last-child) {
              box-shadow: inset -1px 0 0 0 rgba(34, 34, 34, 0.25);
            }
          }
  
          &-svg {
            @include media-breakpoint-down(md) {
              width: 24px;
              margin-right: 8px;
            }
            @include media-breakpoint-up(lg) {
              width: 32px;
              margin-right: 24px;
            }
          }
  
          &-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
  
            @include media-breakpoint-down(md) {
              padding-top: 16px;
              padding-bottom: 16px;
            }
  
            &-key {
              @include media-breakpoint-down(md) {
                font-size: 12px;
                line-height: 1.67;
              }
              @include media-breakpoint-up(lg) {
                font-size: 14px;
                line-height: 1.43;
              }
            }
  
            &-value {
              font-weight: bold;
              @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 1.5;
              }
              @include media-breakpoint-up(lg) {
                font-size: 26px;
                line-height: 1.31;
              }
            }
  
          }
  
        }
  
      }
  
      .detail-section {
  
        @include media-breakpoint-up(lg) {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
        }
  
        &-item {
          @include media-breakpoint-up(lg) {
            width: 470px;
          }
        }
  
        &-item-image {
          img {
            width: 100%;
            object-fit: cover;
          }
        }

        &-item-video {
          position: relative;;

          img {
            width: 100%;
            object-fit: cover;
          }

          // some css come from glightbox and plyr
          button {
            display: block;
            position: absolute;

            border: 0;
            border-radius: 100%;
            color: #fff;
            left: 50%;
            opacity: .9;
            padding: 15px;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            background-color: rgb(0, 179, 255);

            svg {
              left: 2px;
              position: relative;
              fill: currentColor;

              display: block;
              height: 18px;
              pointer-events: none;
              width: 18px;
            }
          }
        }

        &-item-content {
  
          @include media-breakpoint-down(md) {
            padding: 24px 16px;
          }
  
          @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 24px 56px;
          }
  
          .info-section-title {
            font-size: 32px;
            font-weight: bold;
            line-height: 1.25;
            margin-bottom: 16px;
          }
  
          .info-section-price {
            font-size: 18px;
            font-weight: bold;
            opacity: 0.5;
            line-height: 1.33;
          }
  
          .info-section-blurb {
            font-size: 14px;
            line-height: 1.43;
            margin-bottom: 32px;
          }
  
        }
      }
  
    }
  
  }
}
