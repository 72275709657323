@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

$font-family-sans-serif: "Open Sans", sans-serif;

$navbar-light-color: #222222;
$navbar-light-hover-color: lighten(#222222, 10%);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px
);

$pagination-border-radius: 4px;
$pagination-color: #222222;
$pagination-bg: none;
$pagination-border-color: none;

$pagination-color-hover: #ffffff;
$pagination-bg-hover: #222222;
$pagination-border-color-hover: none;

$pagination-color-active: #ffffff;
$pagination-bg-active: #222222;
$pagination-border-color-active: none;

$featured-listing-ribbon-bg: #f4b437;