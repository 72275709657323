.home-builder-index-section {

  .container {

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    .grid {
      display: flex;
      flex-wrap: wrap;

      .grid-item {
        @include media-breakpoint-up(lg) {
          width: 50%;
          padding-left: 16px;
          padding-right: 16px;
          margin-bottom: calc(40px - 1.25rem);
        }

        @include media-breakpoint-down(md) {
          width: 100%;
          margin-bottom: calc(24px - 1.25rem);
        }

        .card {
          border: none;

          &:hover {
            .card-content {
              @include media-breakpoint-up(lg) {
                box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.18);
              }
            }
          }

          .card-content {

            @include media-breakpoint-up(lg) {
              padding: 16px;
              margin-top: -64px;

              border-radius: 4px;
              box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
              background-color: #ffffff;
            }

            @include media-breakpoint-down(md) {
              padding: 16px;
              margin-top: -61px;

              border-radius: 4px;
              box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
              background-color: #ffffff;
            }

            h3 {
              font-size: 32px;
              font-weight: bold;
              line-height: 1.25;
            }

            .price-range {
              font-size: 14px;
              line-height: 1.43;
            }

          }

          .card-cta {
            font-size: 14px;
            line-height: 1.43;
            margin-top: 8px;

            span {
              color: var(--btn-primary-bg-color);
            }
          }

        }

      }
    }

  }
}