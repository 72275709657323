.home-type-page {

  .hero {
    height: 300px;

    @include media-breakpoint-up(lg) {
      height: 550px;
    }
  }

  .floor-plan-cta {
      font-size: 14px;
      line-height: 1.43;
      color: var(--btn-primary-bg-color);
  }

  .floor-plan-cta-img {
    margin-left: 8px;
  }

  .model-photos {
    .card {
      height: 100%;
      &-img-top {
        border-radius: 0;
        height: 200px;
        object-fit: cover;
      }
    }
  }
}

.home-type-section {

  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    margin-top: -72px;
  }

  .container {

    @include media-breakpoint-up(lg) {
      width: 940px;
      padding-left: 0;
      padding-right: 0;
      border-radius: 4px;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
    }

    .attribute-section {
      @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 16px;
        padding-top: 16px;
        background-color: #f5f4f2;
      }

      @include media-breakpoint-up(lg) {
        // make top slider or image under it
        position: relative;
        z-index: 30;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 32px 0;
        background-color: #f5f4f2;
      }

      &-item {
        @include media-breakpoint-down(md) {
          flex: 0 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          &:nth-child(1) {
            box-shadow: inset -1px -1px 0 0 rgba(34, 34, 34, 0.25);
          }
          &:nth-child(2) {
            box-shadow: inset 0 -1px 0 0 rgba(34, 34, 34, 0.25);
          }
          &:nth-child(3) {
            box-shadow: inset -1px 0 0 0 rgba(34, 34, 34, 0.25);
          }
          &:nth-child(4) {
            box-shadow: inset 0 0 0 0 rgba(34, 34, 34, 0.25);
          }
        }

        @include media-breakpoint-up(lg) {
          width: 235px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:not(:last-child) {
            box-shadow: inset -1px 0 0 0 rgba(34, 34, 34, 0.25);
          }
        }

        &-svg {
          @include media-breakpoint-down(md) {
            width: 24px;
            margin-right: 8px;
          }
          @include media-breakpoint-up(lg) {
            width: 32px;
            margin-right: 24px;
          }
        }

        &-text {
          display: flex;
          flex-direction: column;
          justify-content: center;

          @include media-breakpoint-down(md) {
            padding-top: 16px;
            padding-bottom: 16px;
          }

          &-key {
            @include media-breakpoint-down(md) {
              font-size: 12px;
              line-height: 1.67;
            }
            @include media-breakpoint-up(lg) {
              font-size: 14px;
              line-height: 1.43;
            }
          }

          &-value {
            font-weight: bold;
            @include media-breakpoint-down(md) {
              font-size: 16px;
              line-height: 1.5;
            }
            @include media-breakpoint-up(lg) {
              font-size: 26px;
              line-height: 1.31;
            }
          }

        }

      }

    }

    .detail-section {

      @include media-breakpoint-up(lg) {
        display: flex;
        padding: 0;
      }

      &-item {
        @include media-breakpoint-up(lg) {
          width: 470px;
        }
      }

      &-item-map {
        #map {
          @include media-breakpoint-down(md) {
            height: 280px;
          }
          @include media-breakpoint-up(lg) {
            width: 100%;
            height: 100%;
          }
        }
      }

      &-item-content {

        @include media-breakpoint-down(md) {
          padding: 24px 16px;
        }

        @include media-breakpoint-up(lg) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 24px 56px;
        }

        .info-section-title {
          font-size: 32px;
          font-weight: bold;
          line-height: 1.25;
          margin-bottom: 16px;
        }

        .info-section-price {
          font-size: 18px;
          font-weight: bold;
          opacity: 0.5;
          line-height: 1.33;
        }

        .info-section-blurb {
          font-size: 14px;
          line-height: 1.43;
          margin-bottom: 32px;
        }

      }
    }
  }

}

.additional-info-section {
  background-color: #10548a;
  color: #ffffff;

  @include media-breakpoint-up(lg) {
    padding: 56px 150px;
  }

  @include media-breakpoint-down(md) {
    padding: 40px 16px;
  }

  h2 {
    font-weight: bold;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }

  }

  .additional-info-container {
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .additional-info-col {
    padding: 0 10px;
  }

  .additional-info-item {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 16px;
    display: flex;

    &-name {
      width: 104px;
    }

    &-value {
      width: 180px;
      font-weight: bold;
    }

  }

}
