.featured-listings-section {
  overflow: hidden;
  
  h2 {
    font-weight: bold;

    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: 1.25;
    }

    @include media-breakpoint-down(md) {
      font-size: 26px;
      line-height: 1.31;
    }

  }

  .container {
    @include media-breakpoint-down(md) {
      width: 80%;
      padding-left: 0;
      padding-right: 0;
    }

    .owl-stage-outer {
      overflow: visible;

      @include media-breakpoint-up(lg) {
        .owl-item {
          opacity: 0;
          transition: opacity 0.5s linear;

          &.active {
            opacity: 1;
          }
        }
      }
    }
    
    .grid {
      display: flex;
      flex-direction: column;
    }

    .grid-item {
      @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 24px;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
      }

      .card {
        border: none;

        @include media-breakpoint-up(lg) {
          // width: 310px;

          border-radius: 4px;
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
          background-color: #ffffff;

          &:hover {
            top: -0.375rem;
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.18);
          }
        }

        .card-img-top {
          border-radius: 0;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          height: 192px;
        }

        .card-body {
          padding: 16px;

          .card-price {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.43;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
          }

          .card-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.33;
            margin-bottom: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
          }

          .card-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-item {
              display: flex;
              justify-content: space-between;
              align-items: center;

              &-svg {
                margin-right: 8px;
                width: 16px;
              }

              &-text {
                font-size: 14px;
                line-height: 1.43;
              }
            }

            &-vertical-item {
              display: flex;
              flex-direction: column;

              &-svg {
                margin-bottom: 8px;
                width: 16px;
              }

              &-text {
                font-size: 14px;
                line-height: 1.43;
                font-weight: bold;
              }

            }
          }

          .card-cta {
            font-size: 14px;
            line-height: 1.43;
            margin-top: 16px;

            span {
              color: var(--btn-primary-bg-color);
            }
          }
        }
      }
    }
  }
}
