.card-img-callout {
    display: flex;
    align-items: center;

    position: absolute;
    padding: 5px 15px;
    font-size: 13px;
    background: rgba(0,0,0,0.54);
    color: white;

    &-svg {
      width: 13px;
      fill: white;
      margin-right: 5px;
    }
  }