// listing page style

.listing-page-section {
  .container {

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    .grid {
      display: flex;
      flex-wrap: wrap;
    }

    .grid-item {
      @include media-breakpoint-up(lg) {
        width: 33.33%;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 24px;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 24px;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
      }

      .card {
        border: none;

        @include media-breakpoint-up(lg) {
          width: 100%;

          border-radius: 4px;
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
          background-color: #ffffff;

          &:hover {
            top: -0.375rem;
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.18);
          }
        }

        .card-img-top {
          border-radius: 0;
          height: 192px;
        }

        .card-body {
          padding: 16px;

          .card-price {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.43;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
          }

          .card-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.33;
            margin-bottom: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
          }

          .card-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-item {
              display: flex;
              justify-content: space-between;
              align-items: center;

              &-svg {
                margin-right: 8px;
                width: 16px;
              }

              &-text {
                font-size: 14px;
                line-height: 1.43;
              }
            }
          }

          .card-cta {
            font-size: 14px;
            line-height: 1.43;
            margin-top: 16px;

            span {
              color: var(--btn-primary-bg-color);
            }
          }

        }

      }
    }

  }
}