.modal-base {
  @include media-breakpoint-up(lg) {
    // show modal at center of screen
    display: flex;
    align-items: center;
    // greater than 5rem to make modal fixed
    min-height: calc(100% - 5rem);
  }

  @include media-breakpoint-down(md) {
    // sticky modal
    display: flex;
    align-items: flex-end;
    margin: 0;
    min-height: 100%;

    // modal popup from bottom
    .modal.fade & {
      @include transition($modal-transition);
      transform: translate(0, 100%);
    }
    .modal.show & {
      transform: none;
    }
  }

  .modal-content {
    @include media-breakpoint-down(md) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 0;
      padding: 0;
      position: relative;
    }

    &-item {
      @include media-breakpoint-up(lg) {
        width: 100%;
      }
    }

    &-item-image {
      img {
        width: 100%;
        object-fit: cover;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-item-form {
      @include media-breakpoint-down(md) {
        padding: 24px 16px;

        h3 {
          margin-bottom: 24px;
        }
        p {
          margin-bottom: 24px;
        }
      }

      @include media-breakpoint-up(lg) {
        padding: 30px;

        h3 {
          margin-bottom: 24px;
        }
        p {
          margin-bottom: 24px;
        }
      }

      .form-control {
        border: 0;
        box-shadow: none;
        border-bottom: 1px solid black;
        border-radius: 0;

        @include media-breakpoint-up(lg) {
          height: 56px;
          padding: 16px 0px;
        }
      }

      textarea.form-control {
        height: auto;
      }

      .error_input {
        border-bottom-color: #c03;
      }
    }

    > .close {
      position: absolute;
      top: 24px;
      right: 24px;
    }

  }
}

.modal-dialog-take-tour {
  // this value come from the design file
  max-width: 640px;

  .modal-content {
    overflow: hidden;

    &-item {
      @include media-breakpoint-up(lg) {
        flex: 0 0 50%;
      }
    }

    &-item-home-type {
      img {
        width: 100%;
        object-fit: cover;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }

      .card {
        border: none;

        @include media-breakpoint-up(lg) {
          width: 100%;
        }

        .card-img-top {
          border-radius: 0;
          height: 200px;
          object-fit: fill;
        }

        .card-body {
          padding: 24px;
          background-color: #f5f4f2;

          .card-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.33;
            margin-bottom: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
          }

          .card-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-item {
              display: flex;
              justify-content: space-between;
              align-items: center;

              &-svg {
                margin-right: 8px;
                width: 16px;
              }

              &-text {
                font-size: 14px;
                line-height: 1.43;
              }
            }
          }

        }

      }

    }

    &-item-take-tour-form {

      .take-tour-form-container {
        padding: 16px;
      }

      h3 {
        font-size: 26px;
        font-weight: bold;
        line-height: 1.31;

        margin-bottom: 24px;
      }

      .slider-container {
        width: 288px;
        height: 80px;

        position: relative;
        overflow: hidden;

        display: flex;
        align-items: stretch;

        margin-bottom: 24px;
        margin-left: auto;
        margin-right: auto;
      }

      .date-picker-list {
        display: flex;

        height: 100%;
        width: 234px;
        transition: all 0.2s ease;

        .date-picker-item {
          transition-property: transform;
          flex-shrink: 0;
          cursor:pointer;
          width: 70px;
          margin-left: 4px;
          margin-right: 4px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          border-radius: 2px;
          border: solid 1px rgba(34, 34, 34, 0.25);

          &.active {
            background-color: #10548a;
            color: #ffffff;
          }

          .week {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.25;
            text-transform: uppercase;
            margin-bottom: 2px;
          }

          .date {
            font-size: 12px;
            line-height: 1.67;
          }

        }

      }

      .take-tour-pre, .take-tour-next {
        color: #212529;
        background: #f5f4f2;
        border: none;
        cursor: pointer;
        z-index: 2;

        width: 24px;
        padding: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
          outline: none;
        }

      }

      .take-tour-pre {
        margin-right: 3px;
      }

      .take-tour-next {
        margin-left: 3px;
      }

      .time-picker {
        width: 100%;
        height: 34px;
        margin-bottom: 7px;

        border-radius: 2px;
        border: solid 1px rgba(34, 34, 34, 0.25);

        select {
          width: 100%;
          height: 100%;

          // overwrite bootstrap default icon
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          // clear border
          border: none;

          padding-left: 16px;

          /* SVG background image */
          background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
          background-color: #ffffff;
          background-size: .6em;
          background-position: calc(100% - 12px) center;
          background-repeat: no-repeat;

          font-size: 14px;
          line-height: 1.43;
          color: #222222;

          &:focus {
            outline: none;
          }

        }
      }

      .error-message {
        margin-bottom: 5px;
        opacity: 0;
      }

      .btn-cta {
        width: 100%;
      }
    }

  }

}
