html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

// for BS 4.6
a.card {
  color: $gray-900;
  text-decoration: none;
}
