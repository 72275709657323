.section-map {
  &:last-of-type {
    padding-bottom: 0 !important;
  }
  .container {
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    #block-map {
      @include media-breakpoint-down(md) {
        height: 568px;
      }
      @include media-breakpoint-up(lg) {
        height: 600px;
      }
    }
  }

}