$desktop-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
$z-index: 999;

.phone-callout-container {
  &__desktop {
    position: fixed;

    right: 0px;
    bottom: 0px;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .phone-callout-button {
      &.show {
        display: flex;
      }

      &.hide {
        display: none;
      }

      align-items: center;
      justify-content: center;

      height: 50px;
      width: 50px;
      border-radius: 50px;
      margin: 18px;

      border: 2px solid var(--learn-more-bg-color);
      background-color: var(--learn-more-bg-color);

      box-shadow: $desktop-box-shadow;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.1);
      }

      i {
        color: $white;
        font-size: 30px;
      }
    }

    .phone-callout-card {
      &.show {
        display: flex;
      }

      &.hide {
        display: none;
      }

      position: absolute;
      bottom: 0;
      right: 0;

      flex-direction: column;
      align-items: center;

      padding: 15px;
      color: #364650;

      border-radius: 10px;
      border: 1px solid #e8ecf0;
      background-color: $white;

      margin: 16px;
      z-index: $z-index;
      width: 300px;

      box-shadow: $desktop-box-shadow;

      &-close {
        position: absolute;
        right: 6px;
        top: 4px;
        cursor: pointer;
      }

      &-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-size: 14px;
        }
      }

      &-number {
        display: flex;
        align-items: center;
        margin-top: 10px;

        i {
          color: var(--learn-more-bg-color);
          font-size: 20px;
          margin-right: 8px;
        }

        .phone-number {
          font-size: 20px;
          margin-bottom: 0;
          font-weight: 600;

          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
  }

  &__mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: $z-index;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    .phone-callout-button {

      &.show {
        display: flex;
      }

      &.hide {
        display: none;
      }

      align-items: center;
      justify-content: center;
      
      height: 50px;
      width: 50px;
      border-radius: 50px;
      margin: 18px;
      margin-left: auto;

      border: 2px solid var(--learn-more-bg-color);
      background-color: var(--learn-more-bg-color);

      box-shadow: $desktop-box-shadow;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.1);
      }

      i {
        color: $white;
        font-size: 30px;
      }
    }


    .phone-callout-card {

      &.show {
        display: flex;
      }

      &.hide {
        display: none;
      }

      height: 50px;
      background-color: $white;
      box-shadow: rgba(0, 0, 0, 0.1) 0px -5px 10px 0;


      &-cta {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        font-size: 16px;

        color: inherit;
        text-decoration: none;

        &:first-child {
          border-right: 1px solid #e8ecf0;
        }

        i {
          color: var(--learn-more-bg-color);
          font-size: 20px;
          margin-right: 8px;
        }
      }

      &-close {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 6px;
      }
    }
  }
}
