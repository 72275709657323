.hero {
  background-position: center;
  background-size: cover;
  color: white;
  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
    padding-top: 92px;
    padding-bottom: 92px;

    .container {
      .hero--title {
        font-size: 32px;
        font-weight: bold;
        line-height: 1.25;
        margin-bottom: 16px;
      }

      .hero--subtitle {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-top: 100px;
    padding-bottom: 100px;

    .container {
      .hero--title {
        font-size: 56px;
        font-weight: bold;
        line-height: 1.32;
        margin-bottom: 24px;
      }

      .hero--subtitle {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    height: 632px;
  }

  .hero-overlay {
    z-index: 1;
    background-image: linear-gradient(#001924 0,rgba(0,25,36,.738) 19%,rgba(0,25,36,.541) 34%,rgba(0,25,36,.382) 47%,rgba(0,25,36,.278) 56.5%,rgba(0,25,36,.194) 65%,rgba(0,25,36,.126) 73%,rgba(0,25,36,.075) 80.2%,rgba(0,25,36,.042) 86.1%,rgba(0,25,36,.021) 91%,rgba(0,25,36,.008) 95.2%,rgba(0,25,36,.002) 98.2%,rgba(0,25,36,0) 100%);

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .8;
  }

  .no-video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .tv {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background: $gray-600;

    iframe {
      pointer-events: none;
      user-select: none;
    }
  }

  .tv .screen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    opacity: 0;
    transition: opacity .5s;

    &.active {
      opacity: 1;
    }
  }
}