@keyframes bouncing {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 0;
  }
}

.bouncing-arrow {
  z-index: 9999;
  display: flex;
  justify-content: center;

  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  &-text {
    background-color: $white;
    padding: 10px 15px;
    border-radius: 25px;
    font-weight: 400;
    font-size: 1rem;
    position: relative;
    bottom: 45px;

    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
  }

  &-icon {
    animation: bouncing 1s infinite ease-in-out;
    bottom: 0;
    display: block;
    left: 50%;
    margin-left: -15px;
    position: absolute;
    width: 30px;
    color: red;
  }
}
