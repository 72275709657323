.body-copy {
  p {
    font-size: 14px;
  }

  a {
    color: $navbar-light-color;
    font-weight: 600;
    text-decoration: underline;
  }
}