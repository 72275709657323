.title-section {
  margin-top: 80px;
  margin-bottom: 40px;

  h2 {
    font-weight: bold;
  }
}

.gallery-section {

  .container {

    .virtual-tour {
      min-height: 240px;
      margin: 16px 0;
      width: 100%;

      @include media-breakpoint-up(md) {
        min-height: 360px;
      }

      @include media-breakpoint-up(lg) {
        height: 600px;
        max-width: 1068px;
        margin: 0 auto 15px;
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    .grid {
      display: flex;
      flex-wrap: wrap;

      .grid-item {
        @include media-breakpoint-up(lg) {
          width: 33.33%;
          padding-left: 10px;
          padding-right: 10px;
          margin-bottom: 24px;
        }

        @include media-breakpoint-down(md) {
          width: 100%;
          margin-bottom: 24px;
        }

        .card {
          border: none;
          width: 100%;
          color: inherit;
          text-decoration: none;

          &:hover {
            .card-content {
              @include media-breakpoint-up(lg) {
                box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.18);
              }
            }
          }

          .card-img-top { 
            height: 200px;
            object-fit: cover;
          }

          .card-body {
            padding-top: 0;
            padding-bottom: 0;
          }

          .card-content {
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-up(lg) {
              height: 48px;
              margin-top: -24px;

              width: 236px;
              border-radius: 4px;
              box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
              background-color: #ffffff;
            }

            @include media-breakpoint-down(md) {
              margin-top: -24px;
              height: 48px;

              width: calc(100% - 32px);
              margin-left: 16px;

              background-color: #ffffff;

              border-radius: 4px;
              box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
            }

            .cta {
              font-size: 16px;
              line-height: 1.5;
            }

          }

        }
      }

    }
  }
}