.info-section {
  @include media-breakpoint-up(lg) {
    padding: 80px;
  }

  &-normal {
    background-color:#f6f6f6;

    .container {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  &-reverse {
    background-color: #ffffff;

    .container {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .container {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
    @include media-breakpoint-up(lg) {
      justify-content: center;
      align-items: center;
    }
  }

  &-item {
    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    &-photo {
      img {
        @include media-breakpoint-down(md) {
          width: 100%;
          height: 280px;
          object-fit: cover;
        }
        @include media-breakpoint-up(lg) {
          max-width: 100%;
        }
      }
    }

    &-content {

      @include media-breakpoint-down(md) {
        padding: 24px 16px;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 24px 56px;
      }

      &-title {
        font-size: 26px;
        font-weight: bold;
        line-height: 1.31;
        margin-bottom: 16px;
      }

      &-blurb {
        font-size: 14px;
        line-height: 1.43;

        @include media-breakpoint-down(md) {
          margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
          // margin-bottom: 32px;
        }
      }

      .btn {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 180px;
        }
      }

    }

  }

}