.top-bar {
  background-color: var(--learn-more-bg-color);
  width: 100%;

  &__content {
    padding: 0.5rem 1rem;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    display: flex;
    justify-content: center;

    font-size: 14px;
    color: #ffffff;

    @include media-breakpoint-up(lg) {
      padding: 10px 40px;
      justify-content: flex-end;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }
}

.navbar {
  .navbar-toggler {
    border: none;
  }

  .header-logo {
    height: 24px;
    object-fit: contain;
    display: flex;

    &__sur {
      height: 28px;
      width: auto;
      image-rendering: auto;
    }
  }

  .nav-link {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;

    border: 0;
    background-color: transparent;
    outline: none;
  }

  .contact-lg-up {
    color: $navbar-light-color;
    background-color: #ffffff;
    border-radius: 1px;
    border: solid 1px rgba(34, 34, 34, 0.5);
    padding: 7px 16px;
    margin-left: 16px;
  }

  .contact-lg-down {
    color: $navbar-light-color;
    background-color: #ffffff;
    border-radius: 1px;
    border: solid 1px rgba(34, 34, 34, 0.5);
    padding: 7px 16px;
    background-color: transparent;
  }

  @include media-breakpoint-up(lg) {
    padding: 19px 40px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    .nav-item-margin {
      margin-left: 16px;
      margin-right: 16px;
    }

    .header-logo {
      width: auto;
      height: 48px;

      &__sur {
        height: 40px;
      }
    }

    .navbar-nav {
      .nav-link {
        padding: 0;

        border-bottom: 1px solid transparent;
        &.active {
          border-bottom: 1px solid $navbar-light-color;
        }

        &.sub-item {
          font-size: 0.9rem;
          padding: 10px 16px;
          white-space: nowrap;
          text-transform: capitalize;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  // auto hover effect
  .navbar .nav-item.dropdown:hover {
    > .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu .dropdown-toggle:after {
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
  }
  .dropdown-menu .dropdown-menu {
    margin-left: 0;
    margin-right: 0;
  }
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu > li:hover {
    background-color: #f1f1f1
  }
  .dropdown-menu > li:hover > .submenu {
    display: block;
  }

  .dropdown-item {
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1.75rem;

    outline: none;
  }
}
