.standard-page {
  .content {
    section {
      &:first-of-type {
        padding-top: 80px;
      }
  
      &:last-of-type {
        padding-bottom: 80px;
      }
    }
  }

  img.full-width {
    width: 100%;
    object-fit: contain;
  }
}