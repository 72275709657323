.btn-cta {
  border: none;

  color: #ffffff;
  background-color: var(--btn-primary-bg-color);

  &:hover {
    //background-color: darken(var(--btn-primary-bg-color), 10%)
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  @include media-breakpoint-up(lg) {
    border-radius: 2px;
    padding: 11px 26.5px;
    width: 50%;
  }

}
